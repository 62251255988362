<template>
  <div class="container" style="width:97%;">
    <div class="filter-container">
      查询条件：
      <el-date-picker
          v-model="listQuery.DTStartCreatedDate"
          type="date"
          placeholder="开始日期"
          value-format="yyyy-MM-dd"
          clearable
      />
      <el-date-picker
          v-model="listQuery.DTEndCreatedDate"
          type="date"
          placeholder="结束日期"
          style="margin-left: 10px;"
          value-format="yyyy-MM-dd"
          clearable
      />
      <el-select v-model="listQuery.keyName" placeholder="请选择" style="margin-bottom: 10px;margin-left: 10px;" clearable>
        <el-option label="客户名称" value="CustomerName"/>
        <el-option label="项目名称" value="ProjectName"/>
        <el-option label="项目负责人" value="ProjectLeader"/>
      </el-select>
      <el-input style="width: 200px;margin-left: 10px;" placeholder="请输入" v-model="listQuery.keywords" clearable
                @keydown.enter.prevent="getList"/>

      <el-button icon="el-icon-search" type="success" style="margin-bottom: 10px;margin-left: 10px;" size="small"
                 @click="clickFind">查询
      </el-button>

      <el-button
          class="filter-item"
          type="primary"
          size="small"
          icon="el-icon-refresh"
          style="margin-bottom: 10px;margin-left: 5px;"
          @click="resetSearch">重置
      </el-button>
      <el-button
          v-if="!downloadLoading"
          class="filter-item"
          type="success"
          size="small"
          icon="el-icon-download"
          style="margin-bottom: 10px;margin-left: 5px;"
          @click="exportData">导出
      </el-button>
      <el-button
          v-if="downloadLoading"
          :loading="downloadLoading"
          class="filter-item"
          type="success"
          size="small"
          icon="el-icon-download"
          style="margin-bottom: 10px;margin-left: 5px;"
          >正在导出
      </el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;">
      <el-table-column label="项目名称" align="center" width="130px">
        <template v-slot="{row}">
          <span>{{ row.projectName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户" align="center" width="130px">
        <template v-slot="{row}">
          <span>{{ row.customerName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目负责人" align="center">
        <template v-slot="{row}">
          <span>{{ row.projectLeader }}</span>
        </template>
      </el-table-column>
      <el-table-column label="合同额(元)" align="center">
        <template v-slot="{row}">
          <span>{{ row.contractPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="成本" align="center">
        <template v-slot="{row}">
          <span>{{ row.cost }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已收款" align="center">
        <template v-slot="{row}">
          <span>{{ row.receivedPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="应收款" align="center">
        <template v-slot="{row}">
          <span>{{ row.needReceivedPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="已付款" align="center">
        <template v-slot="{row}">
          <span>{{ row.paidPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="应付款" align="center">
        <template v-slot="{row}">
          <span>{{ row.needPaidPrice }}</span>
        </template>
      </el-table-column>

      <el-table-column label="毛利润(元)" align="center">
        <template v-slot="{row}">
          <span>{{ row.profit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="毛利润率" align="center">
        <template v-slot="{row}">
          <span>{{ row.profitMargin }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建日期" align="center">
        <template v-slot="{row}">
          <span>{{ row.created_at }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          label="项目情况"-->
<!--          align="center"-->
<!--          class-name="small-padding fixed-width"-->
<!--          width="100px"-->
<!--          fixed="right">-->
<!--        <template v-slot="{row}">-->
<!--          <el-button type="success" size="mini" @click="visitItem(row)">查看</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div style="margin-top: 15px;margin-bottom: 5px">项目统计：</div>
    <el-table
        :data="statistics"
        border
        fit
        highlight-current-row
        style="width: 100%;">
      <el-table-column label="客户" align="center" width="130px">
        <template v-slot="{row}">
          <span></span>
        </template>
      </el-table-column>
      <el-table-column label="项目名称" align="center" width="130px">
        <template v-slot="{row}">
          <span></span>
        </template>
      </el-table-column>
      <el-table-column label="项目负责人" align="center">
        <template v-slot="{row}">
          <span></span>
        </template>
      </el-table-column>
      <el-table-column label="总合同额(元)" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumContractPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总成本" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumCost }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总已收款" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumReceivedPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总应收款" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumShouleReceive }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总已付款" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumPaidPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总应付款" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumShoulePay }}</span>
        </template>
      </el-table-column>

      <el-table-column label="总毛利润(元)" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumProfit }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总毛利润率" align="center">
        <template v-slot="{row}">
          <span>{{ row.sumProfitMargin }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建日期" align="center">
        <template v-slot="{row}">
          <span></span>
        </template>
      </el-table-column>
    </el-table>

    <!--    點擊查看按鈕-->
    <el-dialog width="50%" title="项目查看" v-model="dialogVisitVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :model="formModel"
          label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectName"
                        placeholder="请填写项目名称"/>
            </el-form-item>
            <el-form-item label="项目名称">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectLeader"
                        placeholder="请填写项目负责人"/>
            </el-form-item>
            <el-form-item label="项目负责人">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.receivedPrice"
                        placeholder="请填写已收款金额"/>
            </el-form-item>
            <el-form-item label="合同额(元)">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectName"
                        placeholder="请填写项目名称"/>
            </el-form-item>
            <el-form-item label="已收款">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.receivedPrice"
                        placeholder="请填写已收款金额"/>
            </el-form-item>
            <el-form-item label="项目情况">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.projectLeader"
                        placeholder="请填写项目负责人"/>
            </el-form-item>

          </el-col>
          <el-col :span="12">
            <el-form-item label="已付款">
              <el-input style="width: 200px"
                        disabled
                        v-model="formModel.customerName"
                        placeholder="请填写客户名称"/>
            </el-form-item>
            <el-form-item label="应付款">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.contractPrice"
                        placeholder="请填写合同金额"/>
            </el-form-item>
            <el-form-item label="应收款">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.paidPrice"
                        placeholder="请填写已付款金额"/>
            </el-form-item>
            <el-form-item label="毛利润(元)">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.contractPrice"
                        placeholder="请填写合同金额"/>
            </el-form-item>
            <el-form-item label="毛利润率">
              <el-input style="width: 200px"
                        type="number"
                        disabled
                        v-model="formModel.paidPrice"
                        placeholder="请填写已付款金额"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ItemStatistics",
  data() {
    return {

      statistics:[],
      //图片上传
      uploadtype: {
        type: 'image'
      },
      uploadAgreementtype: {
        type: 'doc'
      },
      picheaders: {
        'Authorization': localStorage.getItem("token")
      },
      fileList: [],
      SalesInvoiceImgList: [],
      agreementList: [],

      //判斷是否是添加按鈕從而走不同的方法
      dialogImageUrl: '',
      dialogVisible: false,

      dialogVisitVisible: false,

      list: [],
      total: 0,
      listLoading: false,
      downloadLoading: false,
      listQuery: {
        "pageCount": 1000,
        "pageNum": 1,
        keywords: '',
        keyName: '',
        DTStartCreatedDate: '',
        DTEndCreatedDate: '',
        "search": {}
      },
      saleItem: {
        "productCount": '',
        "productName": "",
        "productPrice": ""
      },
      formModel: {
        "contractPrice": "",
        "contracts": [],
        "customerName": "",
        "inputReceipts": [],
        "outReceipts": [],
        "paidPrice": "",
        "needReceivedPrice": "",
        "needPaidPrice": "",
        "projectLeader": "",
        "projectName": "",
        "receivedPrice": "",
        "saleItems": []
      },
      rules: {
        projectName: [{
          required: true, message: '请填写项目名称', trigger: 'blur'
        }],
        customerName: [
          {required: true, message: '请填写客户名称', trigger: 'blur'}
        ],
        projectLeader: [
          {required: true, message: '请填写项目负责人', trigger: 'blur'}
        ],
        contractPrice: [
          {required: true, message: '请填写合同金额', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    this.resetSearch()
    this.getList();
  },
  methods: {
    resetSearch() {
      this.listQuery.keywords = ''
      this.listQuery.keyName = ''

      // 设置 this.listQuery.DTStartCreatedDate 为今年的1月1号
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      this.listQuery.DTStartCreatedDate = new Date(year, 0, 1);

// 设置 this.listQuery.DTEndCreatedDate 为当前时间的明天
      const todayDate = new Date();
      const month = todayDate.getMonth()
      const date = todayDate.getDate()
      this.listQuery.DTEndCreatedDate = new Date(year, month, date);

    },
    clickFind(){
      this.getList()
    },
    getList() {
      // if (this.listQuery.keyName !== '' && this.listQuery.keywords === '') {
      //   this.$message({
      //     message: "请输入查询内容",
      //     type: 'info'
      //   })
      //   return
      // }
      // if (this.listQuery.keyName === '' && this.listQuery.keywords !== '') {
      //   this.$message({
      //     message: "请选择查询条件",
      //     type: 'info'
      //   })
      //   return
      // }
      var param = {
        pageNum: this.listQuery.pageNum,
        pageCount: this.listQuery.pageCount,
        search: {}
      }
      if (this.listQuery.DTStartCreatedDate !== "" && this.listQuery.DTStartCreatedDate !== null) {
        param.search.DTStartCreatedDate = this.listQuery.DTStartCreatedDate
      }
      if (this.listQuery.DTEndCreatedDate !== "" && this.listQuery.DTEndCreatedDate !== null) {
        //注意指针问题
        var endDate = new Date(this.listQuery.DTEndCreatedDate);
        endDate.setDate(endDate.getDate() + 1);
        param.search.DTEndCreatedDate = endDate;
      }
      if (this.listQuery.keywords !== "" && this.listQuery.keyName !== '') {
        param.search[this.listQuery.keyName] = this.listQuery.keywords
      }
      this.listLoading = true

      // this.getlist(this.formModel).then(res => {
      this.$axios({
        url: this.localpath + 'saleproject/projectStatistics',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        //重置
        if (res.data.code === 0) {
          this.list = res.data.msg.projects
          // for (var i in this.list) {
          //   const utcDate = new Date(this.list[i].created_at);
          //   //转换为北京时间
            // const beijingOffset = 8 * 60 * 60 * 1000; // 东八区的时区偏移量，以毫秒为单位
            // const beijingTime = new Date(utcDate.getTime() + beijingOffset);
            // this.list[i].created_at = beijingTime.toISOString().substring(0, 10)// 输出北京时间的 ISO 格式字符串
          // }
          //计算
          for (var i in this.list) {
            let element = this.list[i];
            const utcDate = new Date(element.created_at);
            // 转换为北京时间
            const beijingOffset = 8 * 60 * 60 * 1000; // 东八区的时区偏移量，以毫秒为单位
            const beijingTime = new Date(utcDate.getTime() + beijingOffset);
            element.created_at = beijingTime.toISOString().substring(0, 10)// 输出北京时间的 ISO 格式字符串
          }
          // this.total = res.data.msg.total
          this.$message({
            message: '查询成功',
            type: 'success'
          })
          //项目统计
          var tongji = {
            sumContractPrice: 0,
            sumCost: 0,
            sumPaidPrice: 0,
            sumReceivedPrice: 0,
            sumShoulePay: 0,
            sumShouleReceive: 0,
            sumProfit: 0,
            sumProfitMargin: 0
          }
          for(var i in this.list){
            let element1 = this.list[i];
            tongji.sumContractPrice+=element1.contractPrice
            tongji.sumCost+=element1.cost
            tongji.sumPaidPrice+=element1.paidPrice
            tongji.sumReceivedPrice+=element1.receivedPrice
            tongji.sumShoulePay+=element1.needPaidPrice
            tongji.sumShouleReceive+=element1.needReceivedPrice
            tongji.sumProfit+= element1.profit
          }
          tongji.sumContractPrice=this.formatNumber(tongji.sumContractPrice)
          tongji.sumCost=this.formatNumber(tongji.sumCost)
          tongji.sumPaidPrice=this.formatNumber(tongji.sumPaidPrice)
          tongji.sumReceivedPrice=this.formatNumber(tongji.sumReceivedPrice)
          tongji.sumShoulePay=this.formatNumber(tongji.sumShoulePay)
          tongji.sumShouleReceive=this.formatNumber(tongji.sumShouleReceive)
          tongji.sumProfit=this.formatNumber(tongji.sumProfit)
          tongji.sumProfitMargin=(this.formatNumber(tongji.sumProfit/tongji.sumContractPrice)* 100).toFixed(0) + '%';

          this.statistics = []
          this.statistics.push(tongji)

          this.listLoading = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
          this.listLoading = false
        }
      })
    },
    formatNumber(number) {
      const str = number.toString();

      if (str.includes('.')) {
        const decimalLength = str.split('.')[1].length;

        if (decimalLength > 2) {
          return number.toFixed(2);
        } else if (decimalLength === 1) {
          return number.toFixed(1);
        }
      }

      return number;
    },

    sealItemReq(strID) {
      this.$axios({
        url: this.localpath + 'saleproject/sealProject',
        method: 'post',
        //发送格式为json
        data: JSON.stringify({strId: strID}),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '封存成功',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    },
    visitItem(row) {
      this.formModel = row
      this.fileList = []
      this.agreementList = []
      this.SalesInvoiceImgList = []
      for (var i in this.formModel.inputReceipts) {
        this.fileList.push({url: this.formModel.inputReceipts[i]})
      }
      //想要获取名字，必须上传名字
      for (var i in this.formModel.contracts) {
        this.agreementList.push({
          url: this.formModel.contracts[i].split(',')[0],
          name: this.formModel.contracts[i].split(',')[1]
        })
      }
      for (var i in this.formModel.outReceipts) {
        this.SalesInvoiceImgList.push({url: this.formModel.outReceipts[i]})
      }
      this.dialogVisitVisible = true
    },


    addsaleItem() {
      //验证空
      if (this.saleItem.productName === '' || this.saleItem.productPrice === '' || this.saleItem.productCount === '') {
        this.$message({
          message: '请填写完整的销售内容',
          type: 'error'
        })
        return
      }
      //字符串转为数字
      this.saleItem.productCount = parseInt(this.saleItem.productCount)
      // this.saleItem.productPrice = parseFloat(this.saleItem.productPrice)
      //防止浅复制
      var item = JSON.parse(JSON.stringify(this.saleItem))
      //转为数字


      this.formModel.saleItems.push(item)
      this.saleItem = {
        "productCount": '',
        "productName": "",
        "productPrice": ""
      }
    },

    //图片操作
    beforeUpload(file) {
      console.log(file)
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'jpg'
      const extension2 = testmsg.toLowerCase() === 'jepg'
      const extension3 = testmsg.toLowerCase() === 'png'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传图片只能是jpg/jepg/png格式!',
          type: 'warning'
        });
      }
      return extension || extension2 || extension3
    },

    handlePictureCardPreview(file) {
      console.log('我被点击了')
      window.open(file.url)
    },

    handleChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList

      //移除this.formModel.inputReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.inputReceipts) {
        if (this.formModel.inputReceipts[i] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.inputReceipts.splice(removeindex, 1)
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 0) {
        this.formModel.inputReceipts.push(response.msg)
      } else {
        this.$message({
          message: response.msg,
          type: 'warning'
        });

        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.fileList = fileList

        console.log(file)
        console.log(fileList)
      }
    },

    //图片操作
    SalesInvoiceImghandlePictureCardPreview(file) {

    },
    SalesInvoiceImghandleChange(file, fileList) {
      this.SalesInvoiceImgList = fileList
      console.log(file)
    },
    // 上传图片移除
    SalesInvoiceImghandleRemove(file, fileList) {
      this.SalesInvoiceImgList = fileList

      //移除this.formModel.outReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.outReceipts) {
        if (this.formModel.outReceipts[i] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.outReceipts.splice(removeindex, 1)
    },
    SalesInvoiceImghandleSuccess(response, file, fileList) {
      console.log(file)
      console.log(fileList)
      if (response.code === 0) {
        this.formModel.outReceipts.push(response.msg)
      } else {
        this.$message({
          message: response.msg,
          type: 'warning'
        });

        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.SalesInvoiceImgList = fileList

        console.log(file)
        console.log(fileList)
      }
    },
    agreementHandlePictureCardPreview(file) {
      window.open(file.url)
    },
    agreementBeforeUpload(file) {
      console.log(file)
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg.toLowerCase() === 'pdf'
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension) {
        this.$message({
          message: '上传文件只能是pdf格式!',
          type: 'warning'
        });
      }
      // if(!isLt2M) {
      //     this.$message({
      //         message: '上传文件大小不能超过 10MB!',
      //         type: 'warning'
      //     });
      // }
      // return (extension || extension2) && isLt2M
      return extension
    },
    agreementHandleChange(file, fileList) {
      this.agreementList = fileList
      console.log(file)
    },
    // 上传图片移除
    agreementHandleRemove(file, fileList) {
      this.agreementList = fileList
      //移除this.formModel.outReceipts中的file
      var removeindex = -1
      for (var i in this.formModel.contracts) {
        if (this.formModel.contracts[i].split(',')[0] === file.url) {
          removeindex = i
          break
        }
      }
      this.formModel.contracts.splice(removeindex, 1)
    },
    agreementHandleSuccess(response, file, fileList) {
      console.log(file)
      console.log(fileList)
      // !-----------------问题上传失败依旧显示  成功
      if (response.code === 0) {
        //加上url，上傳後不知道為什麼沒有url
        file.url = response.msg
        fileList.splice(fileList.length - 1, 1)
        fileList.push(file)
        this.agreementList = fileList

        this.formModel.contracts.push(file.url + ',' + file.name)
      } else {
        this.$message({
          message: response.msg,
          type: 'warning'
        });
        var removeindex = -1
        for (var i in fileList) {
          if (fileList[i].name === file.name) {
            removeindex = i
            break
          }
        }
        fileList.splice(removeindex, 1)
        this.agreementList = fileList

        console.log(file)
        console.log(fileList)
      }
    },

    deleteSaleItemsRow(index) {
      this.formModel.saleItems.splice(index, 1)
    },
    handleClose(done) {
      this.$confirm('确认关闭吗？内容不会被保存')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    currPageChange(Page) {
      this.listQuery.pageNum = Page
      this.getList()
    },
    pageSizeChange(Perpage) {
      this.listQuery.pageCount = Perpage
      this.listQuery.pageNum = 1
      this.getList()
    },
    exportData(){
      var param = {
        pageNum: this.listQuery.pageNum,
        pageCount: this.listQuery.pageCount,
        search: {}
      }
      if (this.listQuery.DTStartCreatedDate !== "" && this.listQuery.DTStartCreatedDate !== null) {
        param.search.DTStartCreatedDate = this.listQuery.DTStartCreatedDate
      }
      if (this.listQuery.DTEndCreatedDate !== "" && this.listQuery.DTEndCreatedDate !== null) {
        //注意指针问题
        var endDate = new Date(this.listQuery.DTEndCreatedDate);
        endDate.setDate(endDate.getDate() + 1);
        param.search.DTEndCreatedDate = endDate;
      }
      if (this.listQuery.keywords !== "" && this.listQuery.keyName !== '') {
        param.search[this.listQuery.keyName] = this.listQuery.keywords
      }
      this.downloadLoading = true

      this.$axios({
        url: this.localpath + 'saleproject/exportStatistics',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        responseType: "blob",
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        //重置
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "项目统计" + timem + ".xlsx";
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {
        this.downloadLoading = false
      })
    }
  },
}
</script>

<style scoped>
#upload {
  margin-left: 20px;
  margin-top: 15px;
}

#upload :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 55px;
}

.uploadpdf {
  margin-left: 20px;
  /*margin-top: 15px;*/
}

.uploadpdf :deep() .el-upload--text {
  border: 0px dashed #d9d9d9 !important;
  width: 230px;
  height: 0;
}

.uploadpdf :deep() .el-icon-close {
  display: none;
}

.uploadpdf :deep() .el-icon-close-tip {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload {
  display: none;
}

#uploadVisitjinxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload {
  display: none;
}

#uploadVisitxiaoxiang:deep() .el-upload-list__item-delete {
  display: none;
}

#upload :deep() .el-upload {
  text-align: left;
}
</style>

